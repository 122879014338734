import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { titleCase } from 'title-case';
import groupBy from 'lodash/groupBy';
import {
  Container,
  Box,
  SimpleGrid,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';

import CanonicalLink from 'timelesstime-ui/components/canonical-link';
import SEO from 'timelesstime-ui/components/seo';
import LeadInHeader from 'timelesstime-ui/components/lead-in-header';
import Heading from 'timelesstime-ui/components/heading';
import PageLayout from '../components/layout';

const SiteMap = ({
  data: {
    jsonLdThumbnailImage,
    pages: { nodes: pages },
    services: { nodes: services },
    tools: { nodes: tools },
    caseStudies: { nodes: caseStudies },
    knowledge: { nodes: knowledge },
    events: { nodes: events },
    localPlaces: { nodes: localPlacesNodes },
  },
}) => {
  const localPlaces = groupBy(localPlacesNodes, 'areaName');
  return (
    <PageLayout
      leadInHeader={
        <LeadInHeader
          heading="Let's find what you're looking for"
          contact={null}
          imageType="group"
          crumbs={[
            {
              url: '/sitemap/',
              title: 'Page Index',
            },
          ]}
        />
      }
    >
      <SEO
        title="Sitemap"
        keywords={['hr and management', 'sitemap', 'site index']}
        description="Looking for something? Take a look in our page index"
        canonicalPath="/sitemap/"
        thumbnail={jsonLdThumbnailImage}
      />

      <Container maxW="container.lg">
        <Box as="section" mt={4}>
          <Heading as="h1">We&apos;ve got some interesting pages</Heading>
          <SimpleGrid
            as={UnorderedList}
            mr={0}
            mt={4}
            columns={[1, 1, 2]}
            columnGap={8}
            rowGap={2}
          >
            {pages.map((page) => (
              <Box as={ListItem} key={page.slug}>
                <CanonicalLink color="tt.midBlue" to={page.fields.path}>
                  {page.title}
                </CanonicalLink>
              </Box>
            ))}
          </SimpleGrid>
        </Box>
        <Box as="section" mt={4}>
          <Heading as="h1">We&apos;re experts in people management</Heading>
          <SimpleGrid
            as={UnorderedList}
            mr={0}
            mt={4}
            columns={[1, 1, 2]}
            columnGap={8}
            rowGap={2}
          >
            {services.map((service) => (
              <Box as={ListItem} key={service.id}>
                <CanonicalLink color="tt.midBlue" to={service.fields.path}>
                  {service.title}
                </CanonicalLink>
              </Box>
            ))}
          </SimpleGrid>
        </Box>
        <Box as="section" mt={4}>
          <Heading as="h1">We have great tools</Heading>
          <SimpleGrid
            as={UnorderedList}
            mr={0}
            mt={4}
            columns={[1, 1, 2]}
            columnGap={8}
            rowGap={2}
          >
            {tools.map((tool) => (
              <Box as={ListItem} key={tool.id}>
                <CanonicalLink color="tt.midBlue" to={tool.fields.path}>
                  {tool.title}
                </CanonicalLink>
              </Box>
            ))}
          </SimpleGrid>
        </Box>
        <Box as="section" mt={4}>
          <Heading as="h1">
            How about some case studies showcasing our work?
          </Heading>
          <SimpleGrid
            as={UnorderedList}
            mr={0}
            mt={4}
            columns={[1, 1, 2]}
            columnGap={8}
            rowGap={2}
          >
            {caseStudies.map((caseStudy) => (
              <Box as={ListItem} key={caseStudy.id}>
                <CanonicalLink color="tt.midBlue" to={caseStudy.fields.path}>
                  {caseStudy.title}
                </CanonicalLink>
              </Box>
            ))}
          </SimpleGrid>
        </Box>
        <Box as="section" mt={4}>
          <Heading as="h1">We&apos;ve got plenty of knowledge</Heading>
          <SimpleGrid
            as={UnorderedList}
            mr={0}
            mt={4}
            columns={[1, 1, 2]}
            columnGap={8}
            rowGap={2}
          >
            {knowledge.map((knowledgePage) => (
              <Box as={ListItem} key={knowledgePage.id}>
                <CanonicalLink
                  color="tt.midBlue"
                  to={knowledgePage.fields.path}
                >
                  {knowledgePage.title}
                </CanonicalLink>
              </Box>
            ))}
          </SimpleGrid>
        </Box>
        <Box as="section" mt={4}>
          <Heading as="h1">We share our knowledge at our free events</Heading>
          <SimpleGrid
            as={UnorderedList}
            mr={0}
            mt={4}
            columns={[1, 1, 2]}
            columnGap={8}
            rowGap={2}
          >
            {events.map((event) => (
              <Box as={ListItem} key={event.id}>
                <CanonicalLink color="tt.midBlue" to={event.fields.path}>
                  {event.title}
                </CanonicalLink>
              </Box>
            ))}
          </SimpleGrid>
        </Box>
        <Box as="section" mt={4}>
          <Heading as="h1">We work with clients throughout the country</Heading>
          {Object.keys(localPlaces).map((areaName) => (
            <Fragment key={areaName}>
              <Heading as="h2" mt={4} mb={4}>
                {titleCase(areaName).replace('Uk', 'UK')}
              </Heading>
              <SimpleGrid
                as={UnorderedList}
                mr={0}
                mt={4}
                mb={6}
                columns={[1, 2, 3]}
                columnGap={8}
                rowGap={2}
              >
                {localPlaces[areaName].map(({ placeName, fields }) => (
                  <Box as={ListItem} key={`${areaName}.${placeName}`}>
                    <CanonicalLink color="tt.midBlue" to={fields.path}>
                      {titleCase(placeName)}
                    </CanonicalLink>
                  </Box>
                ))}
              </SimpleGrid>
            </Fragment>
          ))}
        </Box>
      </Container>
    </PageLayout>
  );
};

export const query = graphql`
  query SitemapQuery {
    jsonLdThumbnailImage: file(name: { eq: "TimelessTime-Logo-Square" }) {
      childImageSharp {
        gatsbyImageData(width: 200, placeholder: BLURRED, layout: FIXED)
      }
    }
    pages: allContentfulPage(
      filter: {
        slug: {
          in: [
            "services"
            "tools"
            "case-studies"
            "knowledgebase"
            "search"
            "events"
            "about"
            "contact"
          ]
        }
      }
    ) {
      nodes {
        id
        slug
        title
        fields {
          path
        }
      }
    }
    services: allContentfulService(filter: { slug: { ne: null } }) {
      nodes {
        id
        slug
        title
        fields {
          path
        }
      }
    }
    tools: allContentfulTool(filter: { slug: { ne: null } }) {
      nodes {
        id
        slug
        title
        fields {
          path
        }
      }
    }
    caseStudies: allContentfulCaseStudy(filter: { slug: { ne: null } }) {
      nodes {
        id
        slug
        title
        fields {
          path
        }
      }
    }
    knowledge: allContentfulKnowledge(filter: { slug: { ne: null } }) {
      nodes {
        id
        slug
        title
        fields {
          path
        }
      }
    }
    events: allContentfulEventInstance(filter: { slug: { ne: null } }) {
      nodes {
        id
        slug
        title
        fields {
          path
        }
      }
    }
    localPlaces: allLocalPlace(
      filter: { slug: { ne: null } }
      sort: { fields: [areaName, placeName] }
    ) {
      nodes {
        id
        areaName
        placeName
        fields {
          path
        }
      }
    }
  }
`;

export default SiteMap;
